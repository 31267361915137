<template>
  <transition name="modal">
    <div class="modal-mask" @click="hide()" style="overflow-y: scroll;">
      <div v-if="getModalInfo" class="modal-container" @click.stop>

        <div class="close-div">
          <img @click="hide()" style="opacity: 1; cursor:pointer;width: 13px; height: 13px;"
               src="https://gcdn.bionluk.com/site/icon/tansel_modal_close_ince.svg">
        </div>
        <div class="send-custom-offer-main">

          <p class="custom-offer-header">Hesabımı Askıya Al</p>
          <div>
            <label class="custom-offer-label" style="margin-top: 34px;">Neden hesabını askıya almak istiyorsun?</label>
            <textarea v-model="description" style="width: calc(100% - 22px); height: 98px;" placeholder="Fikirlerin bizim için değerli. Hesabını askıya alma sebebini birkaç cümleyle açıklarsan bu görüşlerin Bionluk’u sizler için daha iyi bir platform haline getirmemiz adına bize yol gösterici olacaktır. Zaman ayırdığın için teşekkürler, yeniden görüşmek dileğiyle..."></textarea>
          </div>
          <div class="seperator"></div>
          <button @click="saveContactInfo()" class="super-button" style="">Hesabımı Askıya Al</button>
        </div>
      </div>
    </div>
  </transition>


</template>

<script>


  //import LogRocket from 'logrocket';

  export default {
    name: "src-pages-modals-closeAccount-v1_mobile",
    data () {
      return {
        description: null
      }
    },
    methods: {
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      logOut() {
        this.api.user.logout(this.$Progress).then(({data}) => {
          let result = data;
          if (result.success) {
            //window.Intercom('shutdown');

            //LogRocket.identify();

            amplitude.regenerateDeviceId();
            amplitude.setUserId(null);
            localStorage.removeItem("token");
            localStorage.removeItem("visitor_id");
            window.location.href = "/"
          } else {
            this.$toasted.global.errorToast({description: result.message});
          }
        }).catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      },

      saveContactInfo() {
        this.api.user.usersHold(this.description)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.logOut();
            } else {
              this.$toasted.global.errorToast({description: result.message})
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      }
    },

    computed: {
      getModalInfo() {
        return true;
      }
    }


  }

</script>

<style scoped lang="scss">
  .modal-container {
    margin-top: 100px;
    padding: 0;
    font-family: 'sofia-pro';
    overflow-y: scroll;
    width: calc(100vw - 30px) !important;
    max-width: 300px;
    max-hight: 400px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #ffffff;
    padding: 40px 12px 26px 12px;
    position: relative;
  }

  .close-div {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .send-custom-offer-main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .custom-offer-header {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
  }

  .custom-offer-label {
    font-size: 14px;
    text-align: left;
    color: #2d3640;
    margin-top: 20px;
    margin-bottom: 10px;
  }


  .seperator {
    width: 770px;
    height: 2px;
    border-bottom: solid 1px #dfe4ec;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .super-button {
    min-width: 170px;
    height: 50px;
    border-radius: 2px;
    background-color: #FD4056;
    &:hover {
      background-color: #e94258;
    }
  }
</style>
